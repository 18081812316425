let ru = {
  translation: {
    heroTitle: "Проектирование и производство лодок",
    heroSubtitle: "Мы занимаемся производством и продажей алюминиевых лодок, адаптированных для эксплуатации в морях и океанах",
    getPromotion: "Получить предложение",

    catalogue: "Каталог",
    metal: "Металл",
    engine: "Мотор",
    fuelTank: "Топл. бак",
    view: "Просмотреть",
    readyModels: "Готовые модели",
    readyForSaleModelsDesc: "Ниже представлены модели лодок, которые прошли все необходимые сертификации и готовы к продаже прямо сейчас",
    certificationPassingModels: "Модели, проходящие сертификацию",
    certificationPassingModelsDesc: "Ниже представдлены модели лодок, которые в данный момент находятся в процессе прохождения необходимых сертификаций и будут доступны для продажи в ближайшее время",
    individualProjects: "Индивидуальные проекты",
    individualProjectsDesc: "Наша компания занимается индивидуальными проектами, ниже представлены варианты ",
    ourProjectOurProduction: "Наш проект, наше производство",
    ourProjectOurProductionDesc: "В соответствии с вашими требованиями, наша компания подготовит для вас индивидуальный проект, пройдет все необходимые сертификации, а также займется производством лодки по подготовленному проекту.",
    yourProjectOurProduction: "Ваш проект, наше производство",
    yourProjectOurProductionDesc: "При наличии уже готового проекта лодки, наша компания займется производством лодки по подготовленному проекту.",
    ourProjectYourProduction: "Наш проект, ваше производство",
    ourProjectYourProductionDesc: "Наша компания подготовит проект и пройдет все необходимые сертификации, а вы возьмете этап производства на себя.",
    elasticApproach: "Мы гибко подходим к требованиям наших клиентов и всегда готовы найти решение в соответствии с любыми требованиями",
    learnMore: "Узнать больше",

    ourAdvantages: "Наши преимущества",
    quality: "Качество",
    qualityDesc: "В производстве используются исключительно высококачественные материалы (морской Aluminium 5083), детали и комплектующие, а лодки строятся опытными и квалифицированными мастерами.",
    responsibility: "Ответственность",
    responsibilityDesc: "При проектировании и производстве лодок, наша компания соблюдает все необходимые стандарты качества как в подборе материалов, так и в работе.",
    guarantee: "Гарантия",
    guaranteeDesc: "Наша компания предоставляет гарантию на корпус каждой модели лодки на 3 года (гарантия действительна только в случаях правильной эксплуатации в соответствии с руководством по использованию).",
    individualApproach: "Индивидуальный подход",
    individualApproachDesc: "Наша компания всегда гибко подходит к запросам клиента и готова найти решение в соответствии с любыми индивидуальными требованиями.",

    whyUs: "Почему мы?",
    certifications: "Сертификация",
    certificationsDesc: "Все модели лодок, которые производит наша компания, без исключения, имеют сертификат качества и CE/E маркировку.",
    reliability: "Надежность",
    reliabilityDesc: "Наши лодки прекрасно показывают себя в различных условиях и подходят как для прибрежных, так и для внутренних вод.",
    universality: "Универсальность",
    universalityDesc: "По завершении производства, все наши лодки проходят тщательные испытания, а также уверенно показали себя в экстремальных ситуациях в северных морях.",
    functionality: "Функциональность",
    functionalityDesc: "Наши лодки круглогодично используются клиентами как в рабочих целях (в т.ч. перевозка материалов, людей), так и для спокойного отдыха в свободное время.",

    contactUs: "Связаться с нами",
    messageSent: "Сообщение отправлено!",
    messageReceived: "Мы получили ваше сообщение и свяжемся с вами в течение следующих 24 часов!",
    name: "Имя",
    surname: "Фамилия",
    emailAndPhone: "E-mail или номер телефона",
    message: "Сообщение",
    send: "Отправить",

    webDevelopment: "Разработка сайта",
    contactsInLatvia: "Контакты в Латвии",
    contactsInNorway: "Контакты в Норвегии",
    contactsInFinland: "Контакты в Финляндии",
    contactsInSweden: "Контакты в Швеции",

    yes: "Да",
    byRequest: "По запросу",

    technicalDetails: "Технические детали",
    equipment: "Оборудование",
    accessories: "Аксессуары",
    length: "Длина",
    width: "Ширина",
    numberOfPassengers: "Количество пассажиров",
    selfDraining: "Дренаж",
    hull: "Корпус",
    comfortSeats: "Удобные сиденья",
    railings: "Поручни",
    deck: "Палуба",
    side: "Внутренняя конструкция",
    totalLength: "Общая длина",
    weight: "Вес",
    loadCapacity: "Общая грузоподъемность",
    approval: "Сертификация",
    unsinkableConstruction: "Непотопляемая конструкция",
    seatBoxes: "Коробки для сидений",
    steeringConsole: "Рулевая консоль",
    flotation: "Плавучесть",
    windshield: "Ветровое стекло",
    bottom: "Днище",
    inboard: "Борта",
    lockableStorageUnits: "Закрываемые рундуки",
    hydraulicSteering: "Гидравлическое рулевое управление",
    pump: "Ручной трюмный насос или электрический насос",
    navigationLights: "Навигационные огни",
    hook: "Крюк для прицепа/блокировки",
    bracket: "Кронштейн датчика",
    outlet: "Розетка 12V",
    fireExtinguisher: "Огнетушитель",
    polycarbonateWindshield: "Рулевая консоль, лобовое стекло из поликарбоната",
    boatRubberFender: "Площадка для плавания",
    hullPainting: "Покраска корпуса",
    ladder: "Лестница для плавания",
    targa: "Тарга",

    mm: "мм",
    m: "м",
    kg: "кг",
    ftLength: "",

    aluminium: "Алюминий",
    polycarbonatMonolit: "Поликарбонатный монолит",

    almarcraft595: "Almarcraft 595",
    almarcraft595Desc: "Наша модель алюминиевой лодки для рыбалки и спорта - идеальный выбор для рыболовов с друзьями и семьями, которые хотят насладиться не только рыбалкой, но и заняться водными видами спорта или покататься на лыжах.",
    almarcraft595Add: "По желанию заказчика устанавливается двигатель (компоненты для двигателя можно заказать самостоятельно или отправить заказчику) и необходимые компоненты (крепление дроссельной заслонки, ключ запуска, тахометр, индикатор наклона)",

    almarcraft595withTarga: "Almarcraft 595 c таргой",
    almarcraft595withTargaDesc: "Оснащение, отделка и детали делают каждую лодку Almarcraft уникальной, но прочный корпус остается неизменным. Сферы применения многочисленны, и мы производим не только прогулочные и рыбацкие лодки, но и лодки профессионального использования.",
    almarcraft595withTargaAdd: "По желанию заказчика устанавливается двигатель (компоненты для двигателя можно заказать самостоятельно или отправить заказчику) и необходимые компоненты (крепление дроссельной заслонки, ключ запуска, тахометр, индикатор наклона)",

    almarcraft470: "Almarcraft 470",
    almarcraft470Desc: "Лодка Almarcraft 470 отлично подойдёт как для рыбалки, так и для семейного отдыха. Можно использовать не только на открытой воде, но и во внутренних водах. В данный момент проходит процесс сертификации.",
    almarcraft470Add: "Обращаем внимание, что эта модель все еще находится на стадии сертификации и очень скоро будет доступна. Для получения дополнительной информации свяжитесь с нами.",

    almarcraft590withCabin: "Almarcraft 590 с кабиной",
    almarcraft590withCabinDesc: "Модель Almarcraft 590 с кабиной может использоваться не только для отдыха и рыбалки, но и как рабочая лодка. Устойчива к суровым погодным условиям Скандинавии.",

    boatUnderDevelopment: "Лодка в стадии разработки",
    boatUnderDevelopmentDesc: "В данный момент лодка находится в стадии разработки, технические данные и фотографии будут добавлены позже, когда лодка начнет проходить сертификацию",
    orangeBannerText: "Обращаем внимание, что эта модель все еще находится на стадии сертификации и очень скоро будет доступна. Для получения дополнительной информации свяжитесь с нами."
  }
};

export default ru;
